var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"00ba42c3d6f3d995c41cee11a11fade4fcc0ad83"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs"
import env from "./env.config"

/*
This file is used to configure Sentry on the client side.
*/

init({
  dsn:
    env.SENTRY_DSN ||
    "https://f98c791ac65e42c2ab6e8d152daa3ca8@o349763.ingest.sentry.io/6140282",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  // Set environment
  environment: env.NEXT_PUBLIC_VERCEL_ENV || "local",

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
